{
    "theory": [
        {
            "start": "8:00 AM",
            "end": "8:50 AM",
            "days": {
                "mon": "A1",
                "tue": "B1",
                "wed": "C1",
                "thu": "D1",
                "fri": "E1"
            }
        },
        {
            "start": "8:55 AM",
            "end": "9:45 AM",
            "days": {
                "mon": "F1",
                "tue": "G1",
                "wed": "A1",
                "thu": "B1",
                "fri": "C1"
            }
        },
        {
            "start": "9:50 AM",
            "end": "10:40 AM",
            "days": {
                "mon": "D1",
                "tue": "E1",
                "wed": "F1",
                "thu": "G1",
                "fri": "TA1"
            }
        },
        {
            "start": "10:45 AM",
            "end": "11:35 AM",
            "days": {
                "mon": "TB1",
                "tue": "TC1",
                "wed": "TD1",
                "thu": "TE1",
                "fri": "TF1"
            }
        },
        {
            "start": "11:40 AM",
            "end": "12:30 PM",
            "days": {
                "mon": "TG1",
                "tue": "TAA1",
                "wed": "TBB1",
                "thu": "TCC1",
                "fri": "TDD1"
            }
        },
        {
            "start": "12:35 PM",
            "end": "1:25 PM",
            "days": {
                "mon": "S11",
                "fri": "S15"
            }
        },
        {
            "lunch": true
        },
        {
            "start": "2:00 PM",
            "end": "2:50 PM",
            "days": {
                "mon": "A2",
                "tue": "B2",
                "wed": "C2",
                "thu": "D2",
                "fri": "E2"
            }
        },
        {
            "start": "2:55 PM",
            "end": "3:45 PM",
            "days": {
                "mon": "F2",
                "tue": "G2",
                "wed": "A2",
                "thu": "B2",
                "fri": "C2"
            }
        },
        {
            "start": "3:50 PM",
            "end": "4:40 PM",
            "days": {
                "mon": "D2",
                "tue": "E2",
                "wed": "F2",
                "thu": "G2",
                "fri": "TA2"
            }
        },
        {
            "start": "4:45 PM",
            "end": "5:35 PM",
            "days": {
                "mon": "TB2",
                "tue": "TC2",
                "wed": "TD2",
                "thu": "TE2",
                "fri": "TF2"
            }
        },
        {
            "start": "5:40 PM",
            "end": "6:30 PM",
            "days": {
                "mon": "TG2",
                "tue": "TAA2",
                "wed": "TBB2",
                "thu": "TCC2",
                "fri": "TDD2"
            }
        },
        {
            "start": "6:35 PM",
            "end": "7:25 PM",
            "days": {
                "mon": "S3",
                "tue": "S1",
                "wed": "S4",
                "thu": "S2"
            }
        }
    ],
    "lab": [
        {
            "start": "08:00 AM",
            "end": "08:50 AM",
            "days": {
                "mon": "L1",
                "tue": "L7",
                "wed": "L13",
                "thu": "L19",
                "fri": "L25"
            }
        },
        {
            "start": "08:50 AM",
            "end": "09:40 AM",
            "days": {
                "mon": "L2",
                "tue": "L8",
                "wed": "L14",
                "thu": "L20",
                "fri": "L26"
            }
        },
        {
            "start": "09:50 AM",
            "end": "10:40 AM",
            "days": {
                "mon": "L3",
                "tue": "L9",
                "wed": "L15",
                "thu": "L21",
                "fri": "L27"
            }
        },
        {
            "start": "10:40 AM",
            "end": "11:30 AM",
            "days": {
                "mon": "L4",
                "tue": "L10",
                "wed": "L16",
                "thu": "L22",
                "fri": "L28"
            }
        },
        {
            "start": "11:40 AM",
            "end": "12:30 PM",
            "days": {
                "mon": "L5",
                "tue": "L11",
                "wed": "L17",
                "thu": "L23",
                "fri": "L29"
            }
        },
        {
            "start": "12:30 PM",
            "end": "1:20 PM",
            "days": {
                "mon": "L6",
                "tue": "L12",
                "wed": "L18",
                "thu": "L24",
                "fri": "L30"
            }
        },
        {
            "lunch": true
        },
        {
            "start": "2:00 PM",
            "end": "2:50 PM",
            "days": {
                "mon": "L31",
                "tue": "L37",
                "wed": "L43",
                "thu": "L49",
                "fri": "L55"
            }
        },
        {
            "start": "2:50 PM",
            "end": "3:40 PM",
            "days": {
                "mon": "L32",
                "tue": "L38",
                "wed": "L44",
                "thu": "L50",
                "fri": "L56"
            }
        },
        {
            "start": "3:50 PM",
            "end": "4:40 PM",
            "days": {
                "mon": "L33",
                "tue": "L39",
                "wed": "L45",
                "thu": "L51",
                "fri": "L57"
            }
        },
        {
            "start": "4:40 PM",
            "end": "5:30 PM",
            "days": {
                "mon": "L34",
                "tue": "L40",
                "wed": "L46",
                "thu": "L52",
                "fri": "L58"
            }
        },
        {
            "start": "5:40 PM",
            "end": "6:30 PM",
            "days": {
                "mon": "L35",
                "tue": "L41",
                "wed": "L47",
                "thu": "L53",
                "fri": "L59"
            }
        },
        {
            "start": "6:30 PM",
            "end": "7:20 PM",
            "days": {
                "mon": "L36",
                "tue": "L42",
                "wed": "L48",
                "thu": "L54",
                "fri": "L60"
            }
        }
    ]
}
